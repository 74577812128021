<template>
  <div class=" container">
    <v-row style="width: 100%">
      <v-col cols="8" offset-lg="2" offset-md="2" class="mt-4">
        <Breadcrumbs
          :items="[
            {
              text: 'All Tickets',
              disabled: false,
              href: '/admin/tickets/all',
            },
            {
              text: 'Verify Tickets',
              disabled: true,
              href: '',
            },
          ]"
        />
        <v-row>
          <v-col>
            <div class="d-flex flex-column mb-3 ml-1 mt-n4">
              <PageTitle
                :text="`Verify Tickets`"
                class=" font font-weight-bold font-size-lg mb-4"
              />
            </div>
            <div class="d-flex flex-column mx-2 flex-grow-1">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(verifyTicket)">
                  <ValidationProvider
                    rules="required|numeric|min:10"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Customer's Phone Number"
                      outlined
                      v-model="ticket.msisdn"
                      hint="Enter customer's phone number "
                      class="font font-weight-medium font-size-md"
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                  <div class="d-flex flex-row flex-grow-1 mt-n3">
                    <span class="font font-weight-medium font-md py-2"
                      >Add Ticket Numbers
                    </span>
                  </div>
                  <div
                    v-for="(number, index) in ticket.tickets"
                    :key="index"
                    class="d-flex flex-row flex-grow-1 flex-wrap"
                  >
                    <ValidationProvider
                      rules="required|min:10"
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Enter Ticket Number"
                        outlined
                        v-model="number.value"
                        hint="Enter customer's ticket number "
                        class="font font-weight-medium font-size-md"
                        :error-messages="errors[0]"
                      />
                      <v-btn
                        v-if="ticket.tickets.length > 1"
                        @click="ticket.tickets.splice(index, 1)"
                        fab
                        icon
                        small
                        color="error"
                        class="mt-2 ml-2"
                      >
                        <i class="material-icons-outlined">delete</i>
                      </v-btn>
                    </ValidationProvider>
                  </div>
                  <div class="d-flex flex-row flex-grow-1 justify-end mb-4">
                    <v-btn
                      @click="ticket.tickets.push({ value: '' })"
                      small
                      color="primary"
                    >
                      <span class="font font-weight-medium font-sm"
                        >New Ticket Row</span
                      >
                    </v-btn>
                  </div>

                  <div class="d-flex flex-row flex-grow-1">
                    <v-spacer />
                    <v-btn
                      :disabled="isLoading || ticket.tickets.length === 0"
                      :loading="isLoading"
                      color="primary"
                      type="submit"
                    >
                      <span class="font font-sm font-weight-medium "
                        >Save
                      </span>
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
              <template v-if="errorTickets.length > 0">
                <v-alert
                  v-for="(error, index) in errorTickets"
                  :key="index"
                  border="left"
                  dense
                  class="mt-2"
                  dismissible
                  type="error"
                >
                  {{
                    error.doesTicketExists && error.isVerified
                      ? `Ticket number - ${error.ticketNumber} already verified`
                      : `Ticket number - ${error.ticketNumber} doesn't exist`
                  }}
                </v-alert>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { numeric, required, min } from 'vee-validate/dist/rules';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
import { loadComponent } from '@/utils/helpers';
import { mapGetters } from 'vuex';
extend('required', {
  ...required,
  message: `Field is required`,
});
extend('numeric', {
  ...numeric,
  message: `Field only accept only numeric characters`,
});
extend('min', {
  ...min,
  message: `Minimum characters must be 10`,
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadComponent(`component/SnackBar`),
    PageTitle: loadComponent(`component/PageTitle`),
    Breadcrumbs: loadComponent(`component/Breadcrumbs`),
  },
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading',
      details: 'staffs/getStaffDetails',
      profile: 'auth/getUserDetails',
      errorTickets: 'tickets/getAllTickets',
    }),
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      ticket: {
        msisdn: '',
        tickets: [{ value: '' }],
      },
    };
  },

  methods: {
    verifyTicket() {
      this.$store.dispatch('tickets/verifyCustomerTickets', {
        ...this.ticket,
        tickets: this.ticket.tickets.map(({ value }) => value),
      });
    },
  },
};
</script>
