var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" container"},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset-lg":"2","offset-md":"2"}},[_c('Breadcrumbs',{attrs:{"items":[
          {
            text: 'All Tickets',
            disabled: false,
            href: '/admin/tickets/all',
          },
          {
            text: 'Verify Tickets',
            disabled: true,
            href: '',
          } ]}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex flex-column mb-3 ml-1 mt-n4"},[_c('PageTitle',{staticClass:" font font-weight-bold font-size-lg mb-4",attrs:{"text":"Verify Tickets"}})],1),_c('div',{staticClass:"d-flex flex-column mx-2 flex-grow-1"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.verifyTicket)}}},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Customer's Phone Number","outlined":"","hint":"Enter customer's phone number ","error-messages":errors[0]},model:{value:(_vm.ticket.msisdn),callback:function ($$v) {_vm.$set(_vm.ticket, "msisdn", $$v)},expression:"ticket.msisdn"}})]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-n3"},[_c('span',{staticClass:"font font-weight-medium font-md py-2"},[_vm._v("Add Ticket Numbers ")])]),_vm._l((_vm.ticket.tickets),function(number,index){return _c('div',{key:index,staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required|min:10","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"label":"Enter Ticket Number","outlined":"","hint":"Enter customer's ticket number ","error-messages":errors[0]},model:{value:(number.value),callback:function ($$v) {_vm.$set(number, "value", $$v)},expression:"number.value"}}),(_vm.ticket.tickets.length > 1)?_c('v-btn',{staticClass:"mt-2 ml-2",attrs:{"fab":"","icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.ticket.tickets.splice(index, 1)}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])]):_vm._e()]}}],null,true)})],1)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end mb-4"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.ticket.tickets.push({ value: '' })}}},[_c('span',{staticClass:"font font-weight-medium font-sm"},[_vm._v("New Ticket Row")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isLoading || _vm.ticket.tickets.length === 0,"loading":_vm.isLoading,"color":"primary","type":"submit"}},[_c('span',{staticClass:"font font-sm font-weight-medium "},[_vm._v("Save ")])])],1)],2)]}}])}),(_vm.errorTickets.length > 0)?_vm._l((_vm.errorTickets),function(error,index){return _c('v-alert',{key:index,staticClass:"mt-2",attrs:{"border":"left","dense":"","dismissible":"","type":"error"}},[_vm._v(" "+_vm._s(error.doesTicketExists && error.isVerified ? ("Ticket number - " + (error.ticketNumber) + " already verified") : ("Ticket number - " + (error.ticketNumber) + " doesn't exist"))+" ")])}):_vm._e()],2)])],1)],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }